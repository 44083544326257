import { render, staticRenderFns } from "./OrganisationDetailsPage.vue?vue&type=template&id=63bc3e96&scoped=true"
import script from "./OrganisationDetailsPage.vue?vue&type=script&lang=ts"
export * from "./OrganisationDetailsPage.vue?vue&type=script&lang=ts"
import style0 from "./OrganisationDetailsPage.vue?vue&type=style&index=0&id=63bc3e96&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63bc3e96",
  null
  
)

export default component.exports