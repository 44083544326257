import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    props: {
        label: {
            type: [String, Object],
            default: ''
        },
        linkLabel: {
            type: [String, Object],
            default: ''
        },
        link: {
            type: String,
            default: '/'
        }
    }
});
