import { defineComponent, ref, computed, watch } from '@vue/composition-api';
import AvatarEditor from '@/Account/components/AvatarEditor.vue';
import SaveFooter from '@/pages/profile/components/SaveFooter.vue';
import SocialNetworksEdit from '@/Organisation/components/SocialNetworksEdit.vue';
import { useGetUserOrganisationCollectionQuery, useCreateCollectionMutation, useUpdateCollectionMutation, UpdateCollectionDocument } from '@/generated-types/graphql.types';
import { getOperationName } from 'apollo-link';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { PublicPageForm } from '@/Organisation/organisation.forms';
import { OrganisationRoutes } from '@/Organisation/organisation.const';
import { useToast } from 'vue-toastification/composition';
export default defineComponent({
    components: {
        AvatarEditor,
        SaveFooter,
        SocialNetworksEdit
    },
    setup(_, context) {
        const { root } = context;
        const toast = useToast();
        const isUpdated = ref(false);
        const isPreviewAvailable = ref(false);
        const isImageSaving = ref(true);
        const form = new PublicPageForm();
        const aboutUsTextLimit = 500;
        // TODO: Remaining symbols logic seems to be duplicated in many components. Consider moving it to WMTextArea
        const remainingAboutUsSymbols = computed({
            get() {
                return form.state.value.aboutUs
                    ? aboutUsTextLimit - form.state.value.aboutUs.length
                    : aboutUsTextLimit;
            },
            set(value) {
                return value;
            }
        });
        const promoTextLimit = 1000;
        const remainingPromoTextSymbols = computed({
            get() {
                return form.state.value.promo
                    ? promoTextLimit - form.state.value.promo.length
                    : promoTextLimit;
            },
            set(value) {
                return value;
            }
        });
        const { result, refetch } = useGetUserOrganisationCollectionQuery({ errorPolicy: 'ignore' });
        const { mutate: createNewCollection } = useCreateCollectionMutation({});
        const { mutate: mutateCollection } = useUpdateCollectionMutation({});
        watch(result, () => {
            if (!result?.value) {
                form.setInitialCompanyName();
                return;
            }
            const userCollection = result.value.get_user_organisation_collection;
            isPreviewAvailable.value = userCollection.has_public_page;
            form.fillState(userCollection);
        }, { immediate: true });
        /**
         * Callback for SocialNetworksEdit component.
         * It updates collection state with updated social media details.
         * @param data
         */
        const socialMediaUpdate = (data) => {
            const socialMedia = form.state.value.socialMedia.find(el => el.social_media_id === data.network);
            if (data.network === 100) {
                form.state.value.website = data.value;
            }
            if (!socialMedia && data.network) {
                form.state.value.socialMedia.push({
                    social_media_id: data.network,
                    social_media_link: data.value
                });
            }
            else if (!data.value) {
                form.state.value.socialMedia = form.state.value.socialMedia.filter(el => el.social_media_id !== data.network);
            }
            else {
                if (socialMedia)
                    socialMedia.social_media_link = data.value;
            }
        };
        /**
         * Callback for AvatarEditor component errors.
         * @param err
         */
        const uploadValidated = (err) => {
            if (err) {
                form.errors.logo = err;
            }
        };
        /**
         * Callback for AvatarEditor component.
         * It is called when image is saved on server.
         * It updates collection data with new image id and updates collection.
         * Runs only if collection is updated.
         * @param imageId
         */
        const imageSaved = (imageId) => {
            if (!imageId) {
                isImageSaving.value = false;
            }
            form.errors.logo = '';
            form.state.value.logoId = imageId;
            if (form.state.value.id && imageId) {
                updateCollection();
            }
        };
        /**
         * Checks if form is changed compared to the form backup.
         */
        const isFormChanged = () => {
            return form.isStateChanged();
        };
        const onClose = () => {
            isUpdated.value = false;
        };
        const isChangesSaved = () => {
            return !isFormChanged() && isUpdated.value;
        };
        /**
         * Reverts form to the backup state and resets all errors.
         */
        const onRevert = () => {
            form.restoreState();
            form.resetErrors();
            isUpdated.value = false;
        };
        /**
         * Execute collection creation or update.
         * When only logo is changed during update process, mutateCollection is not called.
         * Immediately terminates process if logo is not uploaded with the scrolling to the logo uploader.
         */
        const onSaveChanges = () => {
            if (!form.state.value.logo) {
                form.errors.logo = root
                    .$t('organisation.errors.collection_logo.missing');
                root.$scrollTo('#collection-page-form', 500, {
                    offset: -100
                });
                return;
            }
            if (!form.state.value.name) {
                form.errors.name = root.$t('common.errors.required');
                root.$scrollTo('#collectionName', 500, {
                    offset: -100
                });
                return;
            }
            if (form.state.value.id) {
                if (form.isStateLogoChanged() && form.state.value.logo) {
                    form.errors.logo = '';
                    isImageSaving.value = true;
                    return;
                }
                else {
                    updateCollection();
                }
            }
            else {
                createCollection();
            }
        };
        const onActivationButtonClick = () => {
            form.toggleStateIsActivated();
        };
        /**
         * Callback for successful collection creation or update.
         * It sets isUpdated flag to true and refetches collection data.
         */
        const onSuccessfulSave = () => {
            isUpdated.value = true;
            refetch();
        };
        /**
         * Callback for unsuccessful collection creation or update.
         */
        const onUnsuccessfulSave = () => {
            root.$scrollTo('#collection-page-form', 500, {
                offset: -100
            });
            toast.error(root.$i18n.t('common.errors.something_wrong'));
        };
        /**
         * Main submit function for collection data editing
         */
        const updateCollection = async () => {
            const input = {
                collection_id: form.state.value.id,
                about_us: form.state.value.aboutUs,
                promo_text: form.state.value.promo,
                website: form.state.value.website,
                social_media: [],
                image_id: form.state.value.logoId,
                has_public_page: form.state.value.isActivated,
                name: form.state.value.name
            };
            const socialMedia = form.state.value.socialMedia;
            if (socialMedia) {
                input.social_media = socialMedia
                    .filter(el => el.social_media_id !== 100)
                    .map(el => ({
                    social_media_id: el.social_media_id,
                    social_media_link: el.social_media_link
                }));
            }
            if (form.state.value.logoId) {
                input.image_id = form.state.value.logoId;
            }
            mutateCollection({ input }, {
                awaitRefetchQueries: true,
                refetchQueries: [getOperationName(UpdateCollectionDocument)]
            })
                .then(() => {
                onSuccessfulSave();
            })
                .catch(({ graphQLErrors: error }) => {
                onUnsuccessfulSave(error);
            });
        };
        /**
         * Main submit function for collection creation
         */
        const createCollection = async () => {
            const input = {
                collection_id: form.state.value.id,
                about_us: form.state.value.aboutUs,
                promo_text: form.state.value.promo,
                website: form.state.value.socialMedia.find(el => el.social_media_id === 100)
                    ?.social_media_link || null,
                social_media: form.state.value.socialMedia
                    .filter(el => el.social_media_id !== 100)
                    .map(el => ({
                    social_media_id: el.social_media_id,
                    social_media_link: el.social_media_link
                })),
                image_id: form.state.value.logoId,
                has_public_page: form.state.value.isActivated,
                name: form.state.value.name
            };
            createNewCollection({ input })
                .then(() => {
                onSuccessfulSave();
            })
                .catch(({ graphQLErrors: error }) => {
                onUnsuccessfulSave(error);
            });
        };
        /**
         * Handles input event for about us and promo text fields.
         * It updates remaining symbols counter.
         * @param type
         */
        const handleInput = (type) => {
            if (type === 'about') {
                remainingAboutUsSymbols.value =
                    aboutUsTextLimit - form.state.value.aboutUs.length;
            }
            else {
                remainingPromoTextSymbols.value =
                    promoTextLimit - form.state.value.promo.length;
            }
        };
        return {
            aboutUsTextLimit,
            form,
            isImageSaving,
            isPreviewAvailable,
            promoTextLimit,
            remainingAboutUsSymbols,
            remainingPromoTextSymbols,
            OrganisationRoutes,
            handleInput,
            imageSaved,
            isChangesSaved,
            isFormChanged,
            onActivationButtonClick,
            onClose,
            onRevert,
            onSaveChanges,
            socialMediaUpdate,
            uploadValidated,
            useGetLocalizedPath
        };
    }
});
