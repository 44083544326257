import { render, staticRenderFns } from "./PublicPage.vue?vue&type=template&id=29ab28fa&scoped=true"
import script from "./PublicPage.vue?vue&type=script&lang=ts"
export * from "./PublicPage.vue?vue&type=script&lang=ts"
import style0 from "./PublicPage.vue?vue&type=style&index=0&id=29ab28fa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29ab28fa",
  null
  
)

export default component.exports